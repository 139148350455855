import ErrorPage from 'components/molecules/ErrorPage';
import { useRouter } from 'next/router';

function PageNotFound(): JSX.Element {
  const router = useRouter();

  const handleErrorButtonAction = () => {
    router.reload();
  };

  return (
    <ErrorPage
      errorTitle="A comet is passing nearby Earth"
      errorSubtitle="We have an interference in our systems "
      errorMessage="Error 404 (Page not found)."
      onClickButton={handleErrorButtonAction}
    />
  );
}

export default PageNotFound;
